import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { AuthService } from '../services/AuthService'
import { PagePathService } from '../services/PagePathService'

const Page = () => {
  const router = useRouter()
  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      router.replace(PagePathService.myPage())
    } else {
      router.replace(PagePathService.welcome())
    }
  }, [router])
  return null
}

export default Page
